.project-section {
  display: flex;
  gap: 10px;

}

.project-info {
  flex: 2;
  // flex-basis: 60%;
}

.project-image{
  flex:1;
  // flex-basis: 40%;
  img {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
}
